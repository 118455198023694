import React from "react";
import { Img, Text } from "./..";

const Warning = ({ text, showImage = true, isSuccess }) => {
  return (
    <div
      className={`flex md:flex-col self-stretch justify-center items-start w-full gap-2 p-2 mx-auto md:p-5 border border-solid max-w-[1036px] rounded-lg ${
        isSuccess ? 'border-teal-200 bg-teal-100' : 'border-red-900 bg-red-100'
      }`}
    >
      {showImage && (
        <Img
          src={isSuccess ? "images/img_success.svg" : "images/img_phwarning.svg"}
          alt={isSuccess ? "success_icon" : "phwarning_one"}
          className="h-[24px] w-[24px] md:w-full"
        />
      )}
      <Text
        as="p"
        className={`w-[98%] md:w-full tracking-[0.15px] text-base font-normal leading-6 ${
          isSuccess ? '!text-teal-900' : '!text-red-900'
        }`}
      >
        {text}
      </Text>
    </div>
  );
};

export { Warning };
