import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = "v1";

const getOrganizations = async (token) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/api/${VERSION}/organization`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching mailboxes:", error);
    throw error;
  }
};


export { getOrganizations };
