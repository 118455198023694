import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginUser } from "store/slices/UserSlice";
import { isValidEmail } from "../../utils/validations";
import { PasswordInput, Warning } from "components";
import { Spinner } from "flowbite-react";
import { BACKEND_URL } from "config/config";


const SignIn = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("accoun");
  const [cBtn, setCBtn] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [inpval, setInpval] = useState({
    email: "",
    password: "",
  });

  const setVal = (e) => {
    const { name, value } = e.target;
    setInpval((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  useEffect(() => {
    setError("");
    const con = isValidEmail(inpval.email) && inpval.password;
    setCBtn(con);
  }, [inpval]);
  const handleGoogleLogin = async () => {
    try {
      const redirectUrl = encodeURIComponent(
        `${window.location.origin}/success`
      );
      window.location.href = `${BACKEND_URL}/auth/google?redirectUrl=${redirectUrl}`;
    } catch (error) {
      console.error("Google login error:", error);
    }
  };
  const handleSignin = async (e) => {
    e.preventDefault();
    const { email, password } = inpval;
    setLoading(true);
    await dispatch(loginUser({ email, password })).then((res) => {
      if (res.payload === undefined) {
        setError(res.error.message);
      } else {
        navigate("/");
      }
    });
    setLoading(false);
  };

  return (
    <section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
        <div className="max-w-sm mx-auto">
          <div className="text-center mb-6">
            <img
              className="w-auto h-24 mx-auto"
              src="logo.png"
              alt="Scaledmail"
            />
            {/* <h1 className="mt-12 text-3xl font-bold text-gray-900">
              Login to Scaledmail
            </h1> */}
            <p className="mt-4 text-sm font-medium text-gray-500">
              Login to scaledmail.
            </p>
          </div>

          {error && <Warning text={error} showImage={false} />}

          <div className="mt-6">
            <button
              type="button"
              onClick={handleGoogleLogin}
              className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-gray-600 transition-all duration-200 bg-white border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 hover:bg-gray-50 hover:text-gray-900"
            >
              <img
                className="w-5 h-5 mr-2"
                src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/previews/sign-in/1/google-logo.svg"
                alt=""
              />
              Sign in with Google
            </button>
          </div>

          <div className="relative mt-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-gray-200"></div>
            </div>

            <div className="relative flex justify-center">
              <span className="px-2 text-sm text-gray-400 bg-white"> or </span>
            </div>
          </div>

          <div>
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="email"
                  className="text-sm font-bold text-gray-900"
                >
                  Email
                </label>
                <div className="mt-2">
                  <input
                    type="email"
                    name="email"
                    onChange={setVal}
                    value={inpval.email}
                    placeholder="Email address"
                    className="border-[1px] block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="text-sm font-bold text-gray-900"
                  >
                    Password
                  </label>
                  {/* FIXME: */}
                  {/* <a
                    href="#"
                    title=""
                    className="text-sm font-medium text-teal-300 hover:text-indigo-700"
                  >
                    Forgot Password?
                  </a> */}
                </div>
                <PasswordInput inpval={inpval.password} setVal={setVal} name="password"/>
                {/* <div className="mt-2">
                  <input
                    type="password" // Enable password type
                    name="password"
                    onChange={setVal}
                    value={inpval.password}
                    placeholder="Password (min. 8 characters)"
                    className="border-[1px] block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
                  />
                </div> */}
              </div>

              {/* <div className="relative flex items-center">
                <div className="flex items-center h-5">
                  <input
                    type="checkbox"
                    name="remember-password"
                    id="remember-password"
                    className="w-4 h-4 text-teal-300 border-gray-300 rounded focus:ring-teal-300"
                  />
                </div>
                <div className="ml-3">
                  <label
                    htmlFor="remember-password"
                    className="text-sm font-medium text-gray-900"
                  >
                    Remember Me
                  </label>
                </div>
              </div> */}

              <div>
                <button
                  className="inline-flex items-center justify-center w-full px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-teal-300 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-300 hover:bg-teal-600 text-white-A700"
                  disabled={!cBtn}
                  onClick={handleSignin}
                >
                  {loading ? <Spinner /> : "Sign in"}
                </button>
              </div>
            </div>
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm font-medium text-gray-900">
              Don't have an account?{" "}
              <a href="sign-up" className="font-bold hover:underline">
                Sign up now
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SignIn;
