
function getStatusColor(status) {
    switch (status) {
      case "complete":
        return "rgb(106 224 224)";
      case "error":
        return "#ff6868";
      case "running":
        return "rgb(144 187 240)";
      default:
        return "gray"; // Default color for unexpected statuses
    }
  }

const convertToCSV = (objArray) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';

    // Function to recursively convert nested structures to CSV format
    const processValue = (value) => {
        if (value == null || value == undefined) return ''
        if (Array.isArray(value)) {
            return value.map(processValue).join(' | '); // Join list elements with comma
        } else if (typeof value === 'object') {
            return Object.values(value).map(processValue).join(' | '); // Join object values with comma
        } else {
            return value; // No modification for simple values
        }
    };
    const delimeter=','
    // Generate header row
    const header = Object.keys(array[0]).map(key => key.toUpperCase());
    str += header.join(delimeter) + '\r\n';

    // Process each data row
    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (let index in array[i]) {
            const value = array[i][index];
            const new_value=processValue(value)
            console.log(new_value)
            // line += (line !== '' ? delimeter : '') +new_value.replace(/,/g, '|');
        }
        str += line + '\r\n';
    }

    return str;
};


function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  // Options for formatting the date
  const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };

  // Options for formatting the time
  const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit' };

  // Get the formatted date and time
  const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
  const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);

  // Combine date and time
  return `${formattedDate} at ${formattedTime}`;
}


module.exports = { convertToCSV ,formatTimestamp,getStatusColor}