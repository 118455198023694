import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { Input } from "components";
import CustomInput from "components/CustomInput";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function Marketplace() {
  const navigate = useNavigate();
  return (
    <div className="flex h-full  flex-col">
      <div className="flex justify-between items-center">
        <p className="text-xl font-bold text-gray-900">Marketplace</p>
      </div>

      <div className="flex items-center justify-center">
      <div className="grid grid-cols-3 gap-4 my-10">
        {/* FIXME: buy domain */}
          {[
            // {
            //   name: "Buy a Domain",
            //   description:
            //     "Have Scaledmail automatically buy a domain, setup and monitor your DNS, SPF, DKIM and DMARC",
            //   recommeded: true,
            //   link: "nd",
            // },
            {
              name: "Outlook Inboxes",
              // name: "Bring Your Own Domain",
              description:
                "Supply your own domain and configure your DNS setting to line up with our sending infrastructure.",
              link: "yd",
            },
            // {
            //   name: "Google Workspace Inboxes",
            //   description:
            //     "",
            //     // "Premium & Cheap Inboxes",
            //   link: "google-inboxes",
            // },
          ].map((item, i) => (
            <div
              onClick={() => navigate(item.link)}
              key={i}
              className="border rounded-lg p-3 hover:bg-gray-200 cursor-pointer"
            >
              <div className="flex items-center justify-between mb-2">
                <p className="text-lg text-gray-900">{item.name}</p>
                {item?.recommeded && (
                  <p className="text-xs bg-gray-400 rounded-md p-1 text-gray-800">
                    Recommended
                  </p>
                )}
              </div>
              <p className="text-sm text-gray-500">{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
