import { useRoutes } from "react-router-dom";
import { useSelector } from "react-redux";
import NotFound from "pages/NotFound";
import SignIn from "pages/SignIn";
import Layout from "pages/Layout";
import SignUp from "pages/SignUp";
import Billing from "pages/Billing";
import ForgotPassword from "pages/ForgotPassword";
import Verify from "pages/Verify";
import ResetPassword from "pages/ResetPassword";
import Mailboxes from "pages/Mailboxes";
import Domains from "pages/Domains";
import Marketplace from "pages/Marketplace";
import YD from "pages/YD";
import ND from "pages/ND";
import CheckoutDomain from "pages/CD";
import Pricing from "pages/Pricing";

import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Spinner } from "components/Spinner";
import { getUser } from "api/auth";
import Settings from "pages/Settings";
import GoogleInboxes from "pages/GoolgeInboxes";
import Success from "pages/Success";

const PrivateRoute = ({ element }) => {
  const { pathname } = useLocation();
  const { userObj } = useSelector((state) => state.user);
  const [isTokenValid, setIsTokenValid] = useState(true);
  const [isCheckingToken, setIsCheckingToken] = useState(true);

  const checkTokenValidity = (token) => {
    if (!token) return false;

    const tokenPayload = JSON.parse(atob(token.split(".")[1]));
    const expiry = tokenPayload.exp * 1000; // Convert expiry to milliseconds
    return expiry > Date.now();
  };

  const verifyTokenWithBackend = async (userObj) => {
    try {
      const data = await getUser(userObj.token,userObj.organizationId);
      window.Atlas.call("identify", {
        userId: data.organizationId, // Use actual userId
        name: data.name, // Use actual user name
        email: data.email, // Use actual user email
        customFields: {
          product: "Scaledmail",
        },
      });
      return true; // Valid token
    } catch (error) {
      console.error("Error verifying token with backend:", error);
      return false; // Invalid token
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      if (userObj && userObj.token) {
        const localValid = checkTokenValidity(userObj.token);
        if (!localValid) {
          setIsTokenValid(false);
          setIsCheckingToken(false);
        } else {
          const backendValid = await verifyTokenWithBackend(userObj);
          setIsTokenValid(backendValid);
          setIsCheckingToken(false);
        }
      } else {
        setIsTokenValid(false);
        setIsCheckingToken(false);
      }
    };

    checkToken();
  }, [userObj]);

  if (isCheckingToken) {
    return (
      <div className="flex justify-center items-center w-screen h-screen">
        <Spinner />
      </div>
    );
  }

  // Redirect to sign-in if the user is not authenticated
  if (!userObj || !isTokenValid) {
    return <Navigate to="/sign-in" />;
  }

  return element;
};



const ProjectRoutes = () => {
  const { userObj } = useSelector((state) => state.user);
  const isAuthenticated = userObj && userObj.token; // Check if user is logged in

  const routes = [
    // Public routes without layout
    {
      path: "sign-in",
      element: <SignIn />,
    },
    {
      path: "sign-up",
      element: <SignUp />,
    },
    {
      path: "forgot-password",
      element: <ForgotPassword />,
    },
    {
      path: "reset-password",
      element: <ResetPassword />,
    },
    {
      path: "verify",
      element: <Verify />,
    },
    {
      path: "/success",
      element: <Success/>,
    },
    // Private routes with layout and authentication
    {
      path: "/",
      element: isAuthenticated ? <Layout /> : <SignIn />, // Only render Layout if authenticated
      children: [
        { path: "/", element: <PrivateRoute element={<Domains />} /> },
        { path: "mailboxes", element: <PrivateRoute element={<Mailboxes />} /> },
        { path: "pricing", element: <PrivateRoute element={<Pricing />} /> },
        { path: "settings", element: <PrivateRoute element={<Settings/>} /> },
        { path: "marketplace", element: <PrivateRoute element={<Marketplace />} /> },
        { path: "marketplace/yd", element: <PrivateRoute element={<YD />} /> },
        { path: "marketplace/google-inboxes", element: <PrivateRoute element={<GoogleInboxes />} /> },
        { path: "billing", element: <PrivateRoute element={<Billing/>} /> },
        { path: "checkout-domain", element: <PrivateRoute element={<CheckoutDomain />} /> },
      ],
    },

    // Fallback route (Not Found)
    {
      path: "*",
      element: <NotFound />,
    },
  ];

  const element = useRoutes(routes);
  return element;
};

export default ProjectRoutes;
