import { ChevronDownIcon, XCircleIcon } from "@heroicons/react/16/solid";
import React, { useState, useEffect, useRef } from "react";

const MultipleSelectInput = ({
  options = [], // Options as an array of objects [{ label: 'Label', value: 'value' }]
  label = "Select Options",
  placeholder = "Select...",
  selected,
  setSelected,
  multiselect = true, // Default multiselect
  search = true,
  required = true,
}) => {
  const menuRef = useRef(null);
  const [inputVal, setInputVal] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleChange = (event) => {
    setIsOpen(true);
    const val = event.target.value;
    setInputVal(val);
    if (val) {
      const filterOptions = options.filter((option) =>
        option.label.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredOptions(filterOptions);
    } else {
      setFilteredOptions(options);
    }
  };

  const handleSelected = (option) => {
    if (multiselect) {
      if (!selected.find((item) => item.value === option.value)) {
        setSelected([...selected, option]);
      }
    } else {
      setSelected([option]);
      setIsOpen(false);
    }
    setInputVal("");
  };

  const handleRemove = (option) => {
    setSelected(selected.filter((item) => item.value !== option.value));
  };

  const closeOpenMenus = (e) => {
    if (isOpen && menuRef.current && !menuRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus);
    return () => {
      document.removeEventListener("mousedown", closeOpenMenus);
    };
  }, [isOpen]);

  return (
    <div className="relative w-[inherit]" ref={menuRef}>
      {label && (
        <label className="block text-sm font-bold text-gray-900">{label}</label>
      )}
      <div
        className="mt-2 border border-gray-300 rounded-lg cursor-pointer flex items-center justify-between px-4 py-2"
        onClick={handleClick}
      >
        <div className="flex flex-wrap gap-2">
          {selected.length > 0 ? (
            selected.map((option) => (
              <div
                key={option.value}
                className="bg-indigo-100 text-teal-300 text-xs px-2 py-1 rounded-full flex items-center gap-1"
              >
                {option.label}
                {multiselect && (
                  <XCircleIcon
                    className="w-4 h-4 cursor-pointer"
                    onClick={() => handleRemove(option)}
                  />
                )}
              </div>
            ))
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
        </div>
        <ChevronDownIcon className="w-5 h-5 text-gray-400" />
      </div>

      {isOpen && (
        <div className="z-50 mt-1 absolute bg-gray-50 border border-gray-300 rounded-lg shadow-lg max-h-60 overflow-y-auto w-full">
          {search && (
            <div className="p-2">
              <input
                type="text"
                value={inputVal}
                onChange={handleChange}
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-teal-300"
                placeholder="Search..."
              />
            </div>
          )}
          <ul className="p-2">
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => {
                const isSelected = selected.find(
                  (item) => item.value === option.value
                );
                return (
                  <li
                    key={option.value}
                    className={`p-2 cursor-pointer rounded my-1  ${
                      isSelected ? "bg-gray-200" : "hover:bg-gray-100"
                    }`}
                    onClick={() => handleSelected(option)}
                  >
                    {option.label}
                  </li>
                );
              })
            ) : (
              <li className="p-2 text-sm text-gray-500">No options found</li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultipleSelectInput;
