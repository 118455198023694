import { ArrowRightIcon, MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { getDomains } from "api/domain";
import { Button, Input, Text } from "components";
import { Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import SwapDomainModal from "modals/SwapDomain";
import ProfilePictureModal from "modals/ProfilePicture";

export default function Domains() {
  const navigate = useNavigate();

  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [selectedDomain, setSelectedDomain] = useState(null); // Track selected domain (only one)
  const [showModal, setShowModal] = useState(false); // State for modal visibility
  const [showInsantlyModal, setShowInstanlyModal] = useState(false); // State for modal visibility
  const [showPPModal, setShowPPModal] = useState(false); // State for modal visibility

  const loadDomains = async () => {
    setLoading(true);
    try {
      const dt = await getDomains(userObj.token, userObj.organizationId);
      console.info(dt);
      setResults(dt);
    } catch (error) {
      console.error("Error making request:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDomain = async (item) => {
    navigate("/mailboxes", {
      state: { selectedDomain: { value: item.id, label: item.Domain } },
    });
  };

  const handleCheckboxChange = (item) => {
    setSelectedDomain(selectedDomain === item ? null : item);
  };

  const handleSwapDomain = () => {
    setShowModal(true); // Open modal on button click
  };

  const handleAddProfilePictures = () => {
    setShowPPModal(true); // Open modal on button click
  };

  const handleInstantlyUpload = () => {
    setShowInstanlyModal(true); // Open modal on button click
  };

  useEffect(() => {
    loadDomains();
  }, []);

  // Prepare CSV data
  const csvData =
    results?.domains.map((item) => ({
      Domain: item.Domain,
      Mailboxes: item.Mailboxes,
    })) || [];

  return (
    <div className="bg-white sm:py-16 lg:py-20 h-full">
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 h-full">
          {results && (
            <>
              <div className="flex justify-between items-center">
                <p className="text-xl font-bold text-gray-900">
                  Domains ({results.totalRecords})
                </p>

                <div className="flex items-center space-x-2">
                  {selectedDomain && (
                    <>
                      <button
                        onClick={handleSwapDomain}
                        className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                      >
                        Swap Domain
                      </button>
                      <button
                        onClick={handleInstantlyUpload}
                        className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                      >
                        Instantly Upload
                      </button>
                      <button
                        onClick={handleAddProfilePictures}
                        className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                      >
                        Add Profile Pictures
                      </button>
                    </>
                  )}
                  <button
                    // FIXME: your domain
                    onClick={() => navigate("/marketplace/yd")}
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg hover:bg-teal-600"
                  >
                    Order Mailboxes
                  </button>
                  <CSVLink
                    data={csvData}
                    filename={"domains.csv"}
                    className="items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm sm:inline-flex hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                  >
                    Export
                  </CSVLink>
                </div>
              </div>

              <div className="flex flex-col mt-4 lg:mt-8 h-full">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="flex items-center justify-between">
                      <Input
                        placeholder="Search domain"
                        className="w-1/2"
                        prefix={
                          <MagnifyingGlassIcon className="w-5 h-5 mr-5" />
                        }
                      />
                    </div>
                    <div className="h-[75vh] overflow-y-auto">
                      <table className="min-w-full lg:divide-gray-200 divide-y">
                        <thead className="table-header-group">
                          <tr>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"></th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Domain
                            </th>
                            {/* <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Tags
                            </th> */}
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Mailboxes
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              NameServers
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              NameServers Status
                            </th>

                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest"></th>
                          </tr>
                        </thead>

                        <tbody>
                          {results.domains.map((item, index) => (
                            <tr
                              key={index}
                              className={`bg-white ${
                                selectedDomain === item ? "bg-gray-100" : ""
                              }`}
                            >
                              <td className="px-4 py-4">
                                <input
                                  className="rounded-md cursor-pointer"
                                  type="checkbox"
                                  checked={selectedDomain === item}
                                  onChange={() => handleCheckboxChange(item)}
                                />
                              </td>
                              <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                {item.Domain}
                              </td>
                              {/* <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                <div className="flex flex-wrap items-start gap-2">
                                  {["test1", "test2"].map((tag, index) => (
                                    <span
                                      key={index}
                                      className="text-xs font-semibold text-teal-300 bg-indigo-50 border border-teal-300 rounded-full inline-flex items-center px-2.5 py-1"
                                    >
                                      {tag}
                                    </span>
                                  ))}
                                </div>
                              </td> */}
                              <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                {item.Mailboxes}
                              </td>
                              <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                <ul>
                                  {item["NameServer"] &&
                                    item["NameServer"]
                                      .split("\n")
                                      .map((ns, index) => (
                                        <li key={index}>{ns}</li>
                                      ))}
                                </ul>
                              </td>
                              <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                <span className="text-xs font-semibold text-teal-300 bg-teal-50 border border-teal-300 rounded-full inline-flex items-center px-2.5 py-1">
                                  {item["Nameserver Status"]}
                                </span>
                              </td>
                              <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                <ArrowRightIcon
                                  className="w-4 h-4 hover:text-teal-300 cursor-pointer"
                                  onClick={() => handleDomain(item)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      <SwapDomainModal
        showModal={showModal}
        setShowModal={setShowModal}
        selectedDomain={selectedDomain}
      />
      <ProfilePictureModal
        showModal={showPPModal}
        setShowModal={setShowPPModal}
        selectedDomain={selectedDomain}
      />
    </div>
  );
}

// TODO: MULTISELECT