import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import axios from "axios";
import { Input } from "components";
import CustomInput from "components/CustomInput";
import { BACKEND_URL } from "config/config";
import { Button, Spinner } from "flowbite-react";
import React, { useState } from "react";
import { toast } from "react-toastify";

export default function CheckoutDomain() {
  const [domainLoading, setDomainLoading] = useState(false);
  const [domain, setDomain] = useState("");
  const [data, setData] = useState(null);

  return (
    <div className="flex h-full  flex-col">
      
    </div>
  );
}
