import { useEffect, useState } from "react";
import { default as ModalProvider } from "react-modal";
import { toast } from "react-toastify";
import { Elements } from "@stripe/react-stripe-js";
import { Spinner } from "flowbite-react";
import { Button, Text } from "components";
import CustomInput from "components/CustomInput";
import CheckoutForm from "components/CheckoutForm";
import { getStripe } from "utils/getStripe";
import { XMarkIcon } from "@heroicons/react/16/solid";
import { getSubscription } from "api/subscription";
import { formatTimestamp } from "utils/utils";

const ListItem = ({ label, value, circle = false }) => (
  <li className="flex items-center justify-between">
    <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
      {label}
    </p>
    {circle ? (
      <p className="text-xs font-semibold text-teal-300 bg-indigo-50 border border-teal-300 rounded-full inline-flex items-center px-2.5 py-1">
        {value}
      </p>
    ) : (
      <p className="text-base font-bold text-gray-900">{value}</p>
    )}
  </li>
);

export default function OrderDetail({
  userObj,
  showModal,
  setShowModal,
  selectedOrder,
}) {
  const [newDomainName, setNewDomainName] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    console.info(data);
  }, [data]);
  const loadOrder = async () => {
    if (selectedOrder["Orders"] && selectedOrder["Orders"].length > 0) {
      try {
        setLoading(true);
        const resp = await getSubscription(
          userObj.token,
          userObj.organizationId,
          selectedOrder["Orders"][0]
        );
        // console.info(resp);
        setData({ domain_mailbox: true, ...resp });
      } catch (error) {
        console.error("Error in getSubscription:", error);
        toast.error(error?.response?.data?.error || "Unknown Error");
      }
      setLoading(false);
      return;
    }

    // if (selectedOrder['Domain Swap'] && selectedOrder['Domain Swap'].length > 0) {
    //   try {
    //     await getDomainSwap();
    //     console.info("Called getDomainSwap successfully");
    //   } catch (error) {
    //     console.error("Error in getDomainSwap:", error);
    //   }
    // }

    // if (selectedOrder['Instantly Setup'] && selectedOrder['Instantly Setup'].length > 0) {
    //   try {
    //     await getInstantlySetup();
    //     console.info("Called getInstantlySetup successfully");
    //   } catch (error) {
    //     console.error("Error in getInstantlySetup:", error);
    //   }
    // }
  };

  useEffect(() => {
    loadOrder();
  }, [selectedOrder]);

  return (
    <ModalProvider
      appElement={document.getElementById("root")}
      isOpen={showModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-[80vw] p-8 bg-gray-50 rounded-lg max-h-[80%] overflow-y-scroll">
        <div className="w-full mb-3 gap-2 flex">
          <div className="flex flex-col w-full">
            <div className="flex items-center justify-between w-full mb-2">
              <Text size="lg" as="p" className="tracking-[0.18px]">
                Order Details - {selectedOrder.Type}
              </Text>
              <XMarkIcon
                className="h-12 w-12 cursor-pointer"
                onClick={() => setShowModal(false)}
              />
            </div>
            <ul className="mt-6 space-y-5">
              <li className="flex items-center justify-between">
                <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                  Amount
                </p>
                <p className="text-base font-bold text-gray-900">
                  ${selectedOrder["Amount"]}
                </p>
              </li>
              <li className="flex items-center justify-between">
                <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                  Payment Status
                </p>
                <p className="text-base font-bold text-gray-900">
                  {selectedOrder["Payment Status"]}
                </p>
              </li>
              {loading ? (
                <div className="flex items-center justify-center">
                  <Spinner className="h-6 w-6" />
                </div>
              ) : (
                data && (
                  <>
                    <ListItem label="Description" value={data["Nickname"]} />
                    <li className="flex items-center justify-between">
                      <p className="text-xs font-bold tracking-wide text-gray-500 uppercase">
                        Domains
                      </p>
                      <div className="flex gap-2 overflow-x-scroll max-w-2xl">
                        {data["Domain Names"].map((name, i) => (
                          <p
                            key={i}
                            className="text-xs font-semibold text-teal-300 bg-indigo-50 border border-teal-300 rounded-full inline-flex items-center px-2.5 py-1"
                          >
                            {name}
                          </p>
                        ))}
                      </div>
                    </li>
                    <ListItem
                      label="Total Domains"
                      value={data['Domain Names'].length}
                    />
                    {data["domain_mailbox"] && (
                      <>
                        <ListItem
                          label=" Invoice Start Date"
                          value={formatTimestamp(data["Invoice Start Date"])}
                        />
                        <ListItem
                          label=" Invoice End Date"
                          value={formatTimestamp(data["Invoice End Date"])}
                        />
                      </>
                    )}
                    <ListItem
                      label="Status"
                      value={data["Status"]}
                      circle={true}
                    />
                  </>
                )
              )}
            </ul>

            {/* <p className="mt-6 text-xs font-bold tracking-wide text-gray-500 uppercase">
              Description:
            </p>
            <p className="mt-4 text-sm font-medium text-gray-900">
              An NFT is a digital asset that represents real-world objects like
              art, music, in-game products and videos.
            </p> */}
          </div>
        </div>
      </div>
    </ModalProvider>
  );
}
