import { Elements } from "@stripe/react-stripe-js";
import { Button } from "components";
import CheckoutForm from "components/CheckoutForm";
import { GOOGLE_INBOXES } from "config/constants";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { getStripe } from "utils/getStripe";

export default function GoogleInboxes() {
  const steps = [
    {
      title: "Select Package",
      description: "Select the package that best suits your needs.",
    },
    {
      title: "Finalize",
      description: "Finalize your configuration.",
    },
  ];
  const INIT_DATA = {
    priceId: null,
  };
  const [data, setData] = useState(INIT_DATA);

  const [sub, setSub] = useState(GOOGLE_INBOXES[0]);
  const [currentStep, setCurrentStep] = useState(0);

  const handleFilterChange = (field, value) => {
    setData((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleNextStep = () => {
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="flex h-full flex-col pr-3 max-h-[85vh] overflow-auto">
      <p className="text-xl font-bold text-gray-900">
        Google Workspace Inboxes
      </p>

      {/* Step navigation */}
      <div className="py-12 bg-white">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <nav className="flex">
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <a href="#" className="flex items-start">
                  <span
                    className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-sm font-bold ${
                      index === currentStep
                        ? "text-white bg-teal-300"
                        : "text-gray-900 bg-white border border-gray-900"
                    } rounded-full`}
                  >
                    {index + 1}
                  </span>
                  <div className="flex-1 ml-5">
                    <p className="text-base font-bold text-gray-900">
                      {step.title}
                    </p>
                    <p className="mt-2 font-medium text-gray-500">
                      {step.description}
                    </p>
                  </div>
                </a>
                {index < steps.length - 1 && (
                  <svg
                    className="flex-shrink-0 w-12 h-12 text-gray-200"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                )}
              </React.Fragment>
            ))}
          </nav>
        </div>
      </div>

      {/* Step content */}
      <section className="bg-white sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {currentStep === 0 && (
            <div className="grid gap-6 mx-auto mt-8 text-center max-w-5xl grid-cols-2 md:gap-8 sm:mt-16">
              {GOOGLE_INBOXES.map((item, i) => (
                <div
                  key={i}
                  className={`relative overflow-hidden bg-transparent border-2 rounded-md hover:cursor-pointer ${
                    sub.title === item.title
                      ? "border-teal-300"
                      : "border-gray-100"
                  }`}
                  onClick={() => {
                    setSub(item);
                  }}
                >
                  <div
                    className={`absolute top-3 right-3 ${
                      sub.title === item.title ? "" : "hidden"
                    }`}
                  >
                    <svg
                      className="w-6 h-6 text-teal-300"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>

                  <div className="px-4 py-5 lg:p-8">
                    <p className="text-lg font-medium text-gray-500">
                      {item.title}
                    </p>
                    <p className="mt-4 text-6xl font-bold text-black">
                      {item.price}
                    </p>
                    <ul className="flex flex-col mt-8 space-y-1">
                      <li className="text-base font-medium text-gray-900 lg:text-xl">
                        {item.mini_description}
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}

          {currentStep === 1 && (
            <div className="text-center">
              <Elements stripe={getStripe()}>
                <CheckoutForm
                  userData={{
                    ...data,
                    priceId: sub.priceId,
                  }}
                  return_url={`${window.location.origin}/billing`}
                />
              </Elements>
            </div>
          )}

          <div
            className={`mt-8 text-center md:mt-16 flex gap-4 ${
              currentStep == 1 ? "" : ""
            }`}
          >
            {currentStep > 0 && (
              <Button
                className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                onClick={handlePreviousStep}
              >
                Previous
              </Button>
            )}
            {currentStep < 2 && (
              <Button
                className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                onClick={handleNextStep}
              >
                {currentStep < steps.length - 1 ? "Next" : "Finish"}
              </Button>
            )}
          </div>
        </div>
      </section>
    </div>
  );
}
// FIXME: add link or form for input -> https://airtable.com/appMTy1hT948TZMHT/pagiJ3bp2cBF3JQrs/form
