import {
  ArrowTrendingDownIcon,
  ChevronDownIcon,
  PencilSquareIcon,
  PlusIcon,
  XMarkIcon,
  MinusIcon,
} from "@heroicons/react/16/solid";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { Button, Input, Text } from "components";
import CheckoutForm from "components/CheckoutForm";
import CustomInput from "components/CustomInput";
import MultipleSelectInput from "components/MultiSelectInput";
import { PLANS } from "config/constants";
import { Spinner } from "flowbite-react";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { getStripe } from "utils/getStripe";
import { default as ModalProvider } from "react-modal";

function UpdateQuantityModal({
  isShowModal,
  onModalClose,
  currentQty,
  onUpdate,
  ...props
}) {
  const [quantity, setQuantity] = useState(currentQty || 1);

  const handleIncrease = () => {
    if (quantity < 49) {
      setQuantity((prev) => prev + 1);
    } else {
      toast.error("Maximum quantity is 49.");
    }
  };

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
    } else {
      toast.error("Minimum quantity is 1.");
    }
  };

  const handleSubmit = () => {
    onUpdate(quantity); // Call the parent update function with the new quantity
    onModalClose(false); // Close the modal
  };

  return (
    <ModalProvider
      {...props}
      appElement={document.getElementById("root")}
      isOpen={isShowModal}
      className="fixed inset-0 flex items-center justify-center z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="flex flex-col items-center justify-center w-full max-w-md p-8 bg-white rounded-lg shadow-lg bg-white-A700">
        <p className="text-lg font-bold mb-4">Update Quantity</p>

        <div className="flex items-center gap-4 mb-6">
          <button
            className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
            onClick={handleDecrease}
          >
            <MinusIcon className="w-6 h-6 text-gray-600" />
          </button>
          <input
            type="number"
            value={quantity}
            readOnly
            className="w-16 text-center text-xl font-bold border border-gray-300 rounded-lg bg-gray-100 cursor-not-allowed"
          />
          <button
            className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
            onClick={handleIncrease}
          >
            <PlusIcon className="w-6 h-6 text-gray-600" />
          </button>
        </div>

        <div className="flex gap-4">
          <Button
            className="min-w-[100px] px-4 py-2 bg-gray-300 text-gray-800 rounded-lg"
            onClick={() => onModalClose(false)}
          >
            Cancel
          </Button>
          <Button
            className="min-w-[100px] px-4 py-2 bg-teal-300 text-white rounded-lg"
            onClick={handleSubmit}
          >
            Update
          </Button>
        </div>
      </div>
    </ModalProvider>
  );
}

export default function YD() {
  const ESP = [
    { value: "Smartlead", label: "Smartlead" },
    { value: "Instantly", label: "Instantly" },
    { value: "Email Bison", label: "Email Bison" },
  ];
  const steps = [
    {
      title: "Select Package",
      description: "Select the package that best suits your needs.",
    },
    {
      title: "Setup Your Mailboxes",
      description: "Set up your mailboxes to proceed.",
    },
    {
      title: "Finalize",
      description: "Finalize your configuration.",
    },
  ];
  const INIT_DATA = {
    ct: false,
    esp: [],
    qty: 1,
    aliases: [],
    aliases_count: 49,
    sender_name: [{ first_name: "", last_name: "" }],
    domains: [{ domain: "", redirect_to: "" }],
    priceId: null,
  };
  const [data, setData] = useState(INIT_DATA);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = (updated) => {
    setIsModalOpen(false);
  };

  const handleQuantityUpdate = (newQty) => {
    setData((prev) => {
      const updatedDomains = Array(newQty * sub.domains).fill({
        domain: "",
        redirect_to: "",
      });
      return {
        ...prev,
        qty: newQty,
        domains: updatedDomains,
        aliases_count: 49 * newQty * sub.domains, // Adjust mailbox count accordingly
      };
    });
    toast.success(`Quantity updated to ${newQty}`);
  };

  const handleDomainChange = (index, field, value) => {
    setData((prevData) => {
      const updatedDomains = [...prevData.domains];
      updatedDomains[index] = { ...updatedDomains[index], [field]: value };
      return {
        ...prevData,
        domains: updatedDomains,
      };
    });
  };

  const [sub, setSub] = useState(PLANS[0]);
  const [mailboxLoading, setMailboxLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [editingAlias, setEditingAlias] = useState(null);

  const handleFilterChange = (field, value) => {
    setData((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const getMailboxes = async () => {
    const { aliases_count, sender_name } = data; // Now using sender_name list from data
    if (!aliases_count || !sender_name.length) {
      toast.error("Mailboxes information is missing");
      return;
    }

    // Check if any first_name or last_name is empty
    for (let i = 0; i < sender_name.length; i++) {
      const { first_name, last_name } = sender_name[i];
      if (!first_name || !last_name) {
        toast.error(
          `Please fill out or remove the empty fields for sender ${i + 1}`
        );
        return; // Stop execution if validation fails
      }
    }

    try {
      setMailboxLoading(true);

      // Divide aliases count by the number of names
      const aliasesPerName = Math.floor(aliases_count / sender_name.length);
      const remainingAliases = aliases_count % sender_name.length;

      // Initialize the final alias list
      let finalAliases = [];

      // Loop through each name and make API calls
      for (let i = 0; i < sender_name.length; i++) {
        const first_name = sender_name[i].first_name;
        const last_name = sender_name[i].last_name;

        // Determine how many aliases to generate for this name
        const numAliases =
          i < remainingAliases ? aliasesPerName + 1 : aliasesPerName; // Distribute the remainder evenly

        // Make the API call for this name
        const resp = await axios.get(
          `https://useful-endpoints.vercel.app/generate-aliases?first_name=${first_name}&last_name=${last_name}&num=${numAliases}`
        );

        // Add the aliases from this response to the final list, including first_name and last_name
        const aliases =
          resp?.data?.aliases.map((alias) => ({
            alias,
            first_name,
            last_name,
          })) || [];
        finalAliases = [...finalAliases, ...aliases];
      }

      // Set the final aliases list in state, each with its corresponding first_name and last_name
      handleFilterChange("aliases", finalAliases);
      toast.success("Mailboxes generated successfully!");
    } catch (err) {
      console.error(err);
      toast.error(err.response?.data?.error || "Unknown error occurred");
    }

    setMailboxLoading(false);
  };

  const handleNextStep = () => {
    if (currentStep === 1) {
      if (data.domains.length !== sub.domains * data.qty) {
        toast.error(
          `Please ensure you have exactly ${
            sub.domains * data.qty
          } domains and redirects.`
        );
        return;
      }

      // Validate that all domains and redirects are filled
      const emptyFields = data.domains.some(
        (item) => item.domain.trim() === "" || item.redirect_to.trim() === ""
      );

      if (emptyFields) {
        toast.error("Please fill out all domain and redirect fields.");
        return;
      }

      if (!data.aliases.length) {
        toast.error("Generate mailboxes first.");
        return;
      }

      // Proceed to the next step
      setCurrentStep(currentStep + 1);
    } else if (currentStep === 0) {
      setData((prevData) => ({
        ...prevData,
        domains: Array.from({ length: sub.domains * data.qty }, () => ({
          domain: "",
          redirect_to: "",
        })),
        aliases_count: 49 * sub.domains * data.qty,
      }));
      // handleFilterChange("aliases_count", 49 * sub.domains);
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  // For editing aliases (inline or through a modal)
  const handleAliasChange = (index, value) => {
    const updatedAliases = [...data.aliases];
    updatedAliases[index] = value;
    handleFilterChange("aliases", updatedAliases);
  };

  const enableEditing = (index) => {
    setEditingAlias(index);
  };

  const handleEditAlias = (index, value) => {
    handleAliasChange(index, value);
    setEditingAlias(null); // Stop editing after the value is set
  };

  const handleSenderNameChange = (index, field, value) => {
    const updatedSenderNames = [...data.sender_name];
    updatedSenderNames[index][field] = value;
    setData((prevData) => ({
      ...prevData,
      sender_name: updatedSenderNames,
    }));
  };

  // Add new sender name input
  const addSenderName = () => {
    setData((prevData) => ({
      ...prevData,
      aliases: [],
      sender_name: [...prevData.sender_name, { first_name: "", last_name: "" }],
    }));
  };

  // Remove a sender name input
  const removeSenderName = (index) => {
    const updatedSenderNames = data.sender_name.filter((_, i) => i !== index);
    setData((prevData) => ({
      ...prevData,
      aliases: [],
      sender_name: updatedSenderNames,
    }));
  };

  return (
    <div className="flex h-full flex-col pr-3 max-h-[85vh] overflow-auto">
      <p className="text-xl font-bold text-gray-900">Bring Your Own Domain</p>

      {/* Step navigation */}
      <div className="py-12 bg-white">
        <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
          <nav className="flex">
            {steps.map((step, index) => (
              <React.Fragment key={index}>
                <a href="#" className="flex items-start">
                  <span
                    className={`inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-sm font-bold ${
                      index === currentStep
                        ? "text-white bg-teal-300"
                        : "text-gray-900 bg-white border border-gray-900"
                    } rounded-full`}
                  >
                    {index + 1}
                  </span>
                  <div className="flex-1 ml-5">
                    <p className="text-base font-bold text-gray-900">
                      {step.title}
                    </p>
                    <p className="mt-2 font-medium text-gray-500">
                      {step.description}
                    </p>
                  </div>
                </a>
                {index < steps.length - 1 && (
                  <svg
                    className="flex-shrink-0 w-12 h-12 text-gray-200"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="1"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                )}
              </React.Fragment>
            ))}
          </nav>
        </div>
      </div>

      {/* Step content */}
      <section className="bg-white sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          {currentStep === 0 && (
            <div className="grid gap-6 mx-auto mt-8 text-center max-w-5xl grid-cols-2 md:gap-8 sm:mt-16">
              {PLANS.slice(0, PLANS.length - 1).map((item, i) => (
                <div
                  key={i}
                  className={`relative overflow-hidden bg-transparent border-2 rounded-md hover:cursor-pointer ${
                    sub.title === item.title
                      ? "border-teal-300"
                      : "border-gray-100"
                  }`}
                  onClick={() => {
                    setSub(item);
                  }}
                >
                  <div
                    className={`absolute top-3 right-3 ${
                      sub.title === item.title ? "" : "hidden"
                    }`}
                  >
                    <svg
                      className="w-6 h-6 text-teal-300"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>

                  <div className="px-4 py-5 lg:p-8">
                    <p className="text-lg font-medium text-gray-500">
                      {item.title}
                    </p>
                    <p className="mt-4 text-6xl font-bold text-black">
                      ${item.price}
                    </p>
                    <ul className="flex flex-col mt-8 space-y-1">
                      <li className="text-base font-medium text-gray-900 lg:text-xl">
                        {item.mini_description}
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}

          {currentStep === 1 && (
            <div className="flex gap-4 justify-between">
              <div className="flex flex-col w-[60vw]">
                <div className="flex flex-col gap-3">
                  <div className="flex flex-col gap-4">
                    <div className="flex items-center justify-between">
                      <p>
                        Enter domain names and redirects (Total:{" "}
                        {sub.domains * data.qty} pair
                        {data.qty > 1 || sub.domains > 1 ? "s" : ""})
                      </p>
                    </div>

                    <div className="max-h-[300px] overflow-y-auto">
                      {data.domains.map((item, index) => (
                        <div
                          key={index}
                          className="flex gap-2 items-center mb-2"
                        >
                          <span className="text-gray-600 font-bold">
                            {index + 1}.
                          </span>
                          <Input
                            placeholder={`Domain ${index + 1}`}
                            value={item.domain}
                            onChange={(e) =>
                              handleDomainChange(
                                index,
                                "domain",
                                e.target.value
                              )
                            }
                          />
                          <Input
                            placeholder={`Redirect for Domain ${index + 1}`}
                            value={item.redirect_to}
                            onChange={(e) =>
                              handleDomainChange(
                                index,
                                "redirect_to",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex flex-col gap-3 mt-3">
                  <div className="flex gap-4 items-center justify-between">
                    <MultipleSelectInput
                      options={ESP}
                      label="Email Sequencer"
                      placeholder="Which sequencer will you be using for these domains"
                      selected={data.esp}
                      setSelected={(v) => handleFilterChange("esp", v)}
                      search={false}
                    />
                    <div className="flex flex-col items-center gap-4">
                      <label
                        htmlFor="custom-tracking"
                        className="text-sm font-bold text-gray-900"
                      >
                        Custom Tracking
                      </label>
                      <div className="relative flex self-center p-1.5 bg-white border border-gray-200 rounded-lg shadow">
                        {/* Yes Button */}
                        <button
                          type="button"
                          className={`ml-0.5 relative border border-transparent rounded-lg py-1 text-sm font-medium text-gray-700 whitespace-nowrap px-3 ${
                            data.ct ? "bg-teal-300 text-white" : ""
                          }`}
                          onClick={() => handleFilterChange("ct", true)}
                        >
                          Yes
                        </button>

                        {/* No Button */}
                        <button
                          type="button"
                          className={`relative py-1 text-sm font-medium rounded-lg shadow-sm whitespace-nowrap px-3 ${
                            !data.ct ? "bg-teal-300 text-white" : ""
                          }`}
                          onClick={() => handleFilterChange("ct", false)}
                        >
                          No
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-col gap-2">
                    <div className="flex items-center justify-between">
                      <p>Enter sender names for mailboxes</p>
                      <div
                        className="cursor-pointer hover:text-teal-300"
                        onClick={() =>
                          setData({
                            ...data,
                            aliases: [],
                            sender_name: [{ first_name: "", last_name: "" }],
                          })
                        }
                      >
                        Clear all
                      </div>
                    </div>

                    {/* Loop through sender_name list to display dynamic inputs */}
                    {data.sender_name.map((sender, index) => (
                      <div key={index} className="flex gap-2 items-center">
                        <Input
                          placeholder="First Name"
                          className="w-1/2"
                          value={sender.first_name}
                          onChange={(e) =>
                            handleSenderNameChange(
                              index,
                              "first_name",
                              e.target.value
                            )
                          }
                        />
                        <Input
                          placeholder="Last Name"
                          className="w-1/2"
                          value={sender.last_name}
                          onChange={(e) =>
                            handleSenderNameChange(
                              index,
                              "last_name",
                              e.target.value
                            )
                          }
                        />
                        <div className="flex gap-1">
                          {data.sender_name.length > 1 && (
                            <XMarkIcon
                              className="w-8 h-8 hover:!text-teal-300 text-gray-600 cursor-pointer"
                              onClick={() => removeSenderName(index)}
                            />
                          )}
                          <PlusIcon
                            className="w-8 h-8 hover:!text-teal-300 text-gray-600 cursor-pointer"
                            onClick={addSenderName}
                          />
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="flex items-center gap-3 justify-between">
                    <div className="w-1/2">
                      <CustomInput
                        label="Mailboxes"
                        placeholder="Enter how many mailboxes to create"
                        type="number"
                        min="1"
                        max="49"
                        value={data.aliases_count}
                        onChange={(e) =>
                          handleFilterChange("aliases_count", e.target.value)
                        }
                      />
                    </div>
                    <div className="w-1/2 h-full items-end flex">
                      {mailboxLoading ? (
                        <Spinner />
                      ) : (
                        <Button
                          className="rounded-lg bg-teal-300 text-white-A700"
                          onClick={getMailboxes}
                        >
                          Generate Mailboxes
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="relative overflow-hidden bg-transparent border-2 rounded-md border-gray-100 mb-1">
                  <div className="px-4 py-5 lg:p-8 flex flex-col items-center">
                    <p className="text-lg font-medium text-gray-500">
                      {sub.title}
                    </p>
                    <p className="mt-2 text-4xl font-bold text-black">
                      ${sub.price * data.qty}
                    </p>
                    <ul className="flex flex-col mt-2 space-y-1">
                      <li className="text-base font-medium text-gray-900 lg:text-xl">
                        {sub.mini_description}
                      </li>
                    </ul>
                    <div
                      onClick={() => setIsModalOpen(true)}
                      className="bg-gray-200 p-1 rounded-sm cursor-pointer flex flex-row items-center mt-2"
                    >
                      <p className="text-sm">Qty {data.qty}</p>
                      <ChevronDownIcon className="h-6 w-6" />
                    </div>
                  </div>
                </div>

                {data.aliases.length > 0 && (
                  <div className="w-[20vw] gap-3 flex flex-col items-center overflow-y-auto max-h-[30vh] bg-white p-4 bg-gray-100">
                    <p className="font-bold">
                      Mailbox Names ({data.aliases.length})
                    </p>
                    {data.aliases.map((item, i) => (
                      <div
                        key={i}
                        className="bg-white flex justify-between p-2 rounded-lg w-full"
                      >
                        {/* {editingAlias === i ? (
                        <input
                          type="text"
                          value={item}
                          onChange={(e) => handleAliasChange(i, e.target.value)}
                          onBlur={() => handleEditAlias(i, item)}
                          className="p-2 w-full border border-gray-300 rounded-lg"
                        />
                      ) : ( */}
                        <p>{item.alias}</p>
                        {/* )} */}
                        {/* <div className="flex gap-2">
                        <PencilSquareIcon
                          className="w-4 h-4 cursor-pointer"
                          onClick={() => enableEditing(i)}
                        />
                      </div> */}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}

          {currentStep === 2 && (
            <div className="text-center">
              <Elements stripe={getStripe()}>
                <CheckoutForm
                  userData={{
                    ...data,
                    priceId: sub.priceId,
                    domain_type: "Own",
                    esp: data.esp.map((item) => item.label), // Convert to a list of labels
                  }}
                  return_url={`${window.location.origin}/billing`}
                />
              </Elements>
            </div>
          )}

          <div
            className={`mt-8 text-center md:mt-16 flex gap-4 ${
              currentStep == 1 ? "" : "justify-center"
            }`}
          >
            {currentStep > 0 && (
              <Button
                className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                onClick={handlePreviousStep}
              >
                Previous
              </Button>
            )}
            {currentStep < 2 && (
              <Button
                className="min-w-[100px] px-6 py-3 font-semibold !text-white-A700 rounded-md bg-teal-300 focus:opacity-80 hover:opacity-80"
                onClick={handleNextStep}
              >
                {currentStep < steps.length - 1 ? "Next" : "Finish"}
              </Button>
            )}
          </div>
        </div>
      </section>
      {isModalOpen && (
        <UpdateQuantityModal
          isShowModal={isModalOpen}
          onModalClose={handleModalClose}
          currentQty={data.qty}
          onUpdate={handleQuantityUpdate}
        />
      )}
    </div>
  );
}
