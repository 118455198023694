
import { loadStripe } from '@stripe/stripe-js';
import {STRIPE_PUBLISHABLE_KEY} from "../config/config";
let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};

const checkout = async (email,price_id) => {
  const stripe = await getStripe();
  const { error } = await stripe.redirectToCheckout({
    lineItems: [
      {
        price: price_id,
        quantity: 1,
      },
    ],
    mode: 'subscription',
    successUrl: window.location.href,
    cancelUrl: window.location.href,
    customerEmail: email,
  });
  console.warn(error.message);
}
export { getStripe, checkout };
// export default checkout;
