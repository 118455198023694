import axios from "axios";
import { BACKEND_URL } from "../config/config";

const signIn = async (userCred) => {
  const response = await axios.post(`${BACKEND_URL}/auth/login`, userCred);
  return response.data;
};

const signUp = async (userCred) => {
  const response = await axios.post(`${BACKEND_URL}/auth/signup`, userCred);
  return response.data;
};

const resetPassword = async (passCred) => {
  const response = await axios.put(
    `${BACKEND_URL}/auth/update-password`,
    passCred
  );
  return response.data;
};

const verifyOtp = async (email, otp) => {
  const response = await axios.put(`${BACKEND_URL}/auth/verify-otp`, {
    email,
    otp,
  });
  return response.data;
};

const getUser = async (token,organizationId) => {
  const response = await axios.get(
    `${BACKEND_URL}/auth/get-user?token=${token}&organizationId=${organizationId}`
  );
  return response.data;
};

const forgotPassword = async (email) => {
  const response = await axios.post(`${BACKEND_URL}/auth/forgot-password`, {
    email,
  });
  return response.data;
};


export {
  signIn,
  signUp,
  verifyOtp,
  forgotPassword,
  resetPassword,
  getUser,
};
