export const PLANS = [
  {
    emoji: "👋",
    title: "Basic",
    description: "Lorem ipsum dolor sit amet, consec tetur adipis elit",
    mini_description: "1 Domain - 99 Inboxes",
    price: "79",
    domains:1,
    frequency: "/mo/user",
    priceId: "price_1Q8esRBUS24WVOL3t4BNT5HQ",
    features: ["1 Domain", "99 Inboxes", "Live Chat Support"],
    popular: false,
    button: "Get Started",
  },
  {
    emoji: "💪",
    title: "Pro",
    domains:4,
    description: "Lorem ipsum dolor sit amet, consec tetur adipis elit",
    mini_description: "4 Domain - 49 Inboxes",
    price: "199",
    frequency: "/mo/user",
    priceId: "price_1Q8euvBUS24WVOL3h1yieoHx",
    features: ["4 Domains", "49 Inboxes", "Live Chat Support"],
    popular: true,
    button: "Get Started",
  },
  {
    emoji: "🚀",
    title: "Exclusive",
    description: "Lorem ipsum dolor sit amet, consec tetur adipis elit",
    price: "CUSTOM",
    frequency: "",
    priceId: null,
    features: ["Live Chat Support"],
    button: "Contact Us",
  },
];

export const GOOGLE_INBOXES = [
  // {
  //   emoji: "👋",
  //   title: "Cheap Inboxes",
  //   description: "Lorem ipsum dolor sit amet, consec tetur adipis elit",
  //   mini_description: "Monthly",
  //   price: "$3",
  //   domains:1,
  //   frequency: "/mo/user",
  //   priceId: "price_1QFXGFBUS24WVOL3AjZ2Xae8",
  //   features: ["1 Domain", "99 Inboxes", "Live Chat Support"],
  //   popular: false,
  //   button: "Get Started",
  // },
  // FIXME: CHANGE price id here
  {
    emoji: "💪",
    title: "Google Inboxes",
    // title: "Premium Inboxes",
    domains:4,
    description: "Lorem ipsum dolor sit amet, consec tetur adipis elit",
    mini_description: "Monthly",
    price: "5",
    frequency: "/mo/user",
    priceId: "price_1PY0Y7BUS24WVOL3uqNA06zK",
    features: ["4 Domains", "49 Inboxes", "Live Chat Support"],
    popular: true,
    button: "Get Started",
  },
];


export const ADD_PROFILE_PICTURE_PRICE_ID="price_1QNCkzBUS24WVOL3JvX8rZWQ"
export const SWAP_DOMAIN_PRICE_ID="price_1QAYGSBUS24WVOL3SuPOivFl"
export const INSTANTLY_UPLOAD_PRICE_ID="price_1QPIx1BUS24WVOL3yOpXSsFM"