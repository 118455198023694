import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = "v1";

const getDomains = async (token,organizationId) => {
  try {
    const response = await axios.get(`${BACKEND_URL}/api/${VERSION}/domain?token=${token}&organizationId=${organizationId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching searched:", error);
    throw error;
  }
};

export { getDomains };
