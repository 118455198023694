import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = "v1";

const getSubscription = async (token, orgId,recordId) => {
  try {
    const response = await axios.get(
      `${BACKEND_URL}/api/${VERSION}/subscription/${recordId}?token=${token}&&organizationId=${orgId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching subscription:", error);
    throw error;
  }
};

export { getSubscription };
