import { MagnifyingGlassIcon } from "@heroicons/react/16/solid";
import { Button, Input } from "components";
import { Spinner } from "flowbite-react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CSVLink } from "react-csv";
import { getMailboxes } from "api/mailbox";
import MultipleSelectInput from "components/MultiSelectInput";
import { getDomains } from "api/domain";
import { useLocation } from "react-router-dom";

export default function Mailboxes() {
  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [domains, setDomains] = useState([]);
  const [selectedDomain, setSelectedDomain] = useState([]);
  const location = useLocation(); // Get the passed state
  useEffect(() => {
    if (location.state?.selectedDomain) {
      setSelectedDomain([location.state.selectedDomain]); // Set the passed domain as selected
    }
  }, [location.state]);

  const loadMailboxes = async () => {
    setLoading(true);
    try {
      // Build the request body with domain IDs if any domains are selected
      const requestBody = {
        domain_ids: selectedDomain.map((domain) => domain.value), // Send domain IDs as a list
      };

      const response = await getMailboxes(userObj.token,userObj.organizationId, requestBody); // Pass the body to POST request
      setResults(response);
    } catch (error) {
      console.error("Error fetching mailboxes:", error);
    } finally {
      setLoading(false);
    }
  };
  const loadDomains = async () => {
    try {
      const dt = await getDomains(userObj.token,userObj.organizationId);
      const domainList = dt.domains.map((item) => ({
        value: item.id,
        label: item.Domain,
      }));
      setDomains(domainList);
    } catch (error) {
      console.error("Error making request:", error);
    }
  };
  useEffect(() => {
    loadDomains();
  });
  useEffect(() => {
    loadMailboxes();
  }, [selectedDomain]);

  // FIXME: whole data download
  // Prepare CSV data
  const csvData =
    results?.mailboxes.map((item) => ({
      Domain: item.Domain,
      Mailboxes: item.Mailboxes,
    })) || [];
  return (
    <div className="bg-white sm:py-16 lg:py-20">
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8 h-full">
          {results && (
            <>
              <p className="text-xl font-bold text-gray-900">
                Mailboxes
                {/* Mailboxes ({results.totalRecords}) */}
              </p>
              <div className="mt-4 flex justify-between items-center gap-2">
                <Input
                  placeholder="Search mailboxes"
                  className="w-1/2"
                  prefix={<MagnifyingGlassIcon className="w-5 h-5 mr-5" />}
                />
                {domains && domains.length > 0 && (
                  <div className="w-1/2 flex items-start justify-end">
                  {/* <div className="w-1/2"> */}
                    <MultipleSelectInput
                      options={domains}
                      label=""
                      placeholder="Select a Domain"
                      selected={selectedDomain}
                      setSelected={setSelectedDomain}
                      multiselect={true}
                    />
                  {/* </div> */}
                  </div>
                )}
              </div>

              <div className="flex flex-col mt-4 lg:mt-8 h-full">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                    <div className="h-[75vh] overflow-y-auto">
                      <table className="min-w-full lg:divide-gray-200 divide-y">
                        <thead className="table-header-group">
                          <tr>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Email
                            </th>
                            <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Sender Name
                            </th>
                            {/* <th className="py-3.5 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-widest">
                              Tags
                            </th> */}
                          </tr>
                        </thead>

                        <tbody>
                          {results.mailboxes.map((item, index) => (
                            <tr key={index} className="bg-white">
                              <td className="px-4 py-4 text-sm font-bold text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                {item.Email}
                              </td>

                              <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                {item["First Name"]} {item["Last Name"]}
                              </td>
                              {/* <td className="px-4 py-4 text-sm font-medium text-gray-900 align-top lg:align-middle whitespace-nowrap">
                                <div className="flex flex-wrap items-start gap-2">
                                  {["tag1", "tag2"].map((tag, index) => (
                                    <span
                                      key={index}
                                      className="text-xs font-semibold text-teal-300 bg-indigo-50 border border-teal-300 rounded-full inline-flex items-center px-2.5 py-1"
                                    >
                                      {tag}
                                    </span>
                                  ))}
                                </div>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
