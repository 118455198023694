import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img, Warning, PasswordInput } from "../../components";
import { checkPasswordStrength } from "../../utils/validations";
import { Spinner } from "components/Spinner";
import { useLocation, useNavigate } from 'react-router-dom';
import { resetPassword } from "api/auth";

const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const name = params.get("name");

    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [pVal, setPVal] = useState({ 1: true, 2: true, 3: true });
    const [cBtn, setCBtn] = useState(true);
    const [inpval, setInpval] = useState({
      password: '',
      cPassword: ''
    });

    const setVal = (e) => {
      const { name, value } = e.target;
      setInpval((prev) => ({
        ...prev,
        [name]: value,
      }));
      if (name === "password") {
        const { rules } = checkPasswordStrength(value, name);
        setPVal(rules);
      }
    };

    // Redirect to sign-up if token or name is missing
    useEffect(() => {
      if (!token || !name) {
        navigate("/sign-up");
      }
    }, [token, name, navigate]);

    useEffect(() => {
      setError('');
      const { isStrong } = checkPasswordStrength(inpval.password, name);
      const con = isStrong && inpval.password === inpval.cPassword;
      setCBtn(con);
    }, [inpval, name]);

    const handleResetPassword = async () => {
      const { password } = inpval; // Destructure within signUp
      setLoading(true);
      try {
        console.info(await resetPassword({ resetPasswordToken: token, password }));
        navigate('/sign-in');
      } catch (error) {
        if (
          error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message);
        }
        else {
          setError('Failed to reset password. Please try again later.');
        }
      }
      setLoading(false);
    };

    return (
      <>    
        <Helmet>
            <title>Scaledmail</title>
            <meta name="description" content="Scaledmail" />
        </Helmet>
        <div className="flex justify-center items-center h-[1080px] w-full md:h-auto px-14 py-[358px] md:p-5">
            <div className="flex flex-col items-center w-[36%] md:w-full gap-[31px] p-8 sm:p-5 bg-white-A700 shadow-xs rounded-[16px]">
                {error && <Warning text={error} showImage={false} />}
                <Text as="h2" className="!text-teal-500 tracking-[0.15px] capitalize text-xl">
                  Scaledmail by beanstalk
                </Text>
                <div className="flex flex-col self-stretch items-center gap-[19px]">
                    <Text size="md" as="p" className="tracking-[0.18px]">
                      Reset Password
                    </Text>
                </div>
                <PasswordInput onChange={setVal} name="password" value={inpval.password} />
                <PasswordInput placeholder="Enter your password again here" label="Confirm Password" onChange={setVal} name="cPassword" value={inpval.cPassword} />
                <div className="flex flex-col self-stretch gap-2">
                  {["Password Strength:", "At least 8 characters", "Contains a number or symbol", "Cannot contain your name or email address"].map((txt, i) => (
                      <div className="flex items-center gap-2 flex-1" key={i}>
                        {i !== 0 &&
                          <Img src={pVal[i] ? "images/img_check.svg" : "images/img_remove.svg"} alt="password" className="h-[18px] w-[18px]" />
                        }
                        <div className="flex">
                          <Text as="p" className={`self-end ${pVal[i] ? "!text-gray-600" : "!text-red-500"} tracking-[0.15px]`}>
                            {txt}
                          </Text>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="flex flex-col self-stretch items-center gap-[19px]">
                  {loading ? <Spinner /> : (
                    <Button shape="round" className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium" onClick={handleResetPassword} disabled={!cBtn}>
                      Reset
                    </Button>
                  )}
                  <Button shape="round" className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium" onClick={() => navigate('/forgot-password')}>
                    Back
                  </Button>
                </div>
            </div>
        </div>
      </>
    );
}

export default ResetPassword;
