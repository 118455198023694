import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function Settings() {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user.userObj);
  const [profile, setProfile] = useState({
    name:user.name,
    email:user.email
  });

  return (
    <main>
    <div class="py-6">
        <div class="px-4 mx-auto sm:px-6 md:px-8">
            <h1 class="text-2xl font-bold text-gray-900">Settings</h1>
        </div>

        <div class="px-4 mx-auto mt-8 sm:px-6 md:px-8">
            <div class="w-full pb-1 overflow-x-auto">
                <div class="border-b border-gray-200">
                    <nav class="flex -mb-px space-x-10">
                        <a href="#" class="py-4 text-sm font-medium text-teal-300 transition-all duration-200 border-b-2 border-teal-300 whitespace-nowrap"> Profile </a>
{/* 
                        <a href="#" class="py-4 text-sm font-medium text-gray-500 transition-all duration-200 border-b-2 border-transparent hover:border-gray-300 whitespace-nowrap"> Password </a>

                        <a href="#" class="py-4 text-sm font-medium text-gray-500 transition-all duration-200 border-b-2 border-transparent hover:border-gray-300 whitespace-nowrap"> Team </a>

                        <a href="#" class="py-4 text-sm font-medium text-gray-500 transition-all duration-200 border-b-2 border-transparent hover:border-gray-300 whitespace-nowrap"> Notification </a>

                       */}
                    </nav>
                </div>
            </div>

            <div class="mt-6">
                <p class="text-base font-bold text-gray-900">Profile</p>
                <p class="mt-1 text-sm font-medium text-gray-500">Update Your Profile Information</p>
            </div>

            <form action="#" method="POST" class="max-w-3xl mt-12">
                <div class="space-y-8">
               
                    <div class="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                        <label for="" class="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"> Name </label>
                        <div class="mt-2 sm:mt-0 sm:col-span-2">
                            <div class="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-5">
                                <div>
                                    <input type="text" name="" id="" placeholder="" value={profile.name} class="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-5 sm:items-start">
                        <label for="" class="block text-sm font-bold text-gray-900 sm:mt-px sm:pt-2"> Email Address </label>
                        <div class="mt-2 sm:mt-0 sm:col-span-2">
                            <input type="email" name="" id="" placeholder="" value={profile.email} class="border block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300" />
                        </div>
                    </div>
                </div>

                {/* <div class="mt-6 sm:mt-12">
                    <button
                        type="submit"
                        class="inline-flex items-center justify-center px-6 py-3 text-sm font-semibold leading-5 text-white transition-all duration-200 bg-teal-300 border border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-300 hover:bg-teal-600"
                    >
                        Update
                    </button>
                </div> */}
            </form>
        </div>
    </div>
</main>

  );
}
