import axios from "axios";
import { BACKEND_URL } from "../config/config";

const VERSION = "v1";

const getMailboxes = async (token, organizationId,data) => {
  try {
    const response = await axios.post(
      `${BACKEND_URL}/api/${VERSION}/mailbox?organizationId=${organizationId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching mailboxes:", error);
    throw error;
  }
};


export { getMailboxes };
