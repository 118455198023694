import { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/16/solid";


const PasswordInput = ({ inpval, setVal,name }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="mt-2 relative flex items-center">
      <input
        type={isPasswordVisible ? "text" : "password"}
        name={name}
        onChange={setVal}
        value={inpval}
        placeholder="Password (min. 8 characters)"
        className="border-[1px] block w-full px-4 py-3 placeholder-gray-500 border-gray-300 rounded-lg focus:ring-teal-300 focus:border-teal-300 sm:text-sm caret-teal-300"
      />
      <div className="absolute right-3">
        {isPasswordVisible ? (
          <EyeIcon className="h-6 w-6 text-gray-600 cursor-pointer hover:ring-teal-300"  onClick={togglePasswordVisibility}/>
        ) : (
          <EyeSlashIcon className="h-6 w-6 text-gray-600 cursor-pointer hover:ring-teal-300"  onClick={togglePasswordVisibility}/>
        )}
      </div>
    </div>
  );
};


export { PasswordInput };
