import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  Cog8ToothIcon,
  CreditCardIcon,
  GlobeAltIcon,
  InboxStackIcon,
  PlusIcon,
} from "@heroicons/react/16/solid";
import { clearUser, setOrganization } from "store/slices/UserSlice";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizations } from "api/organization";

export default function Layout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation(); // Get the current route
  const userObj = useSelector((state) => state.user.userObj);
  const handleLogout = () => {
    dispatch(clearUser());
    navigate("/sign-in");
  };
  // #4ecda5


  const getNavLinkClass = (path) => {
    return location.pathname === path
      ? "flex items-center px-4 py-2.5 text-sm font-medium text-white-A700 bg-teal-300 rounded-lg transition-all duration-200 group"
      : "flex items-center px-4 py-2.5 text-sm font-medium text-gray-900 rounded-lg hover:bg-gray-200 transition-all duration-200 group";
  };

  const [organizations, setOrganizations] = useState([]);
  const [profileDropdown, setProfileDropdown] = useState(false);
  const [orgLoading, setOrgLoading] = useState(false);
  const [orgDropdown, setOrgDropdown] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState(userObj.organizationName);
  const toggleOrganization = () => {
    setOrgDropdown(!orgDropdown);
  };

  const handleSelectItem = (item) => {
    setSelectedOrg(item.Name);
    setOrgDropdown(false);
    // Dispatch the action to update the Redux state with the new organization
    dispatch(
      setOrganization({ organizationName: item.Name, organizationId: item.ID })
    );
    window.location.reload();
  };

  const loadOrganizations = async () => {
    setOrgLoading(true);
    try {
      const response = await getOrganizations(userObj.token); // Pass the body to POST request
      setOrganizations(response);
    } catch (error) {
      console.error("Error fetching mailboxes:", error);
    } finally {
      setOrgLoading(false);
    }
  };
  useEffect(() => {
    loadOrganizations();
  }, [userObj]);
  const toggleProfileDropdown = () => {
    setProfileDropdown(!profileDropdown);
  };

  return (
    <div className="flex flex-col flex-1 bg-white min-h-screen">
      <header className="bg-white border-b border-gray-200">
        <div className="px-4 mx-auto">
          <div className="flex items-center justify-between h-16">
            {/* <div className="flex items-center -m-2 lg:hidden">
              <button
                type="button"
                className="inline-flex items-center justify-center p-2 text-gray-400 bg-white rounded-lg hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-300"
              >
                <svg
                  className="w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                </svg>
              </button>
            </div> */}

            <div className="flex mr-auto lg:ml-0">
              <div className="flex items-center flex-shrink-0">
                <img
                  className="block w-auto h-12"
                  src="logo.png"
                  alt="Scaledmail"
                />
                <img
                  className="hidden w-auto h-8 lg:block"
                  src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/logo.svg"
                  alt=""
                />
              </div>
            </div>

            <div className="mr-5 rounded-md">
              <button
                id="dropdownNavbarLink"
                onClick={toggleOrganization}
                className="text-gray-700 hover:bg-gray-50 border-b border-gray-100 md:hover:bg-transparent md:border-0 pl-3 pr-4 py-2 md:hover:text-blue-700 md:p-0 font-medium flex items-center justify-between w-full md:w-auto"
              >
                <p className="min-w-[130px]">{selectedOrg}</p>
                <svg
                  className="ml-2 -mr-0.5 h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9"
                  ></path>
                </svg>
              </button>

              {orgDropdown && (
                <div
                  id="dropdownNavbar"
                  className="absolute mt-2 bg-white-A700 text-base z-10 list-none divide-y divide-gray-100 rounded shadow w-44"
                >
                  <ul className="py-1" aria-labelledby="dropdownNavbarLink">
                    {organizations.map((item) => (
                      <li key={item.ID}>
                        <button
                          onClick={() => handleSelectItem(item)}
                          className="text-sm hover:bg-gray-100 text-gray-700 block w-full text-left px-4 py-2"
                        >
                          {item.Name}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="relative">
              <button
                type="button"
                onClick={toggleProfileDropdown}
                className="flex items-center max-w-xs rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-300"
              >
                <div className="flex items-center justify-center w-8 h-8 bg-gray-300 rounded-full text-sm font-medium text-gray-900">
                  {userObj.name
                    .split(" ")
                    .map((word, index) =>
                      index < 2 ? word[0].toUpperCase() : ""
                    )
                    .join("")}
                </div>

                <span className="hidden ml-2 text-sm font-medium text-gray-900 md:block">
                  {userObj.name}
                </span>
              </button>

              {profileDropdown && (
                <div className="absolute right-0 mt-2 bg-white-A700 text-base z-10 list-none rounded shadow w-44">
                  <ul className="py-1">
                    <li>
                      <button
                        onClick={() => navigate("/settings")}
                        className="text-sm hover:bg-gray-100 text-gray-700 block w-full text-left px-4 py-2"
                      >
                        Profile
                      </button>
                    </li>
                    <li>
                      <button
                        onClick={handleLogout}
                        className="text-sm hover:bg-gray-100 text-gray-700 block w-full text-left px-4 py-2"
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <div className="flex flex-1">
        <div className="border-r border-gray-200 md:flex md:w-64 md:flex-col">
          <div className="flex flex-col pt-5 overflow-y-auto h-full">
            <div className="flex flex-col justify-between flex-1 h-full px-4">
              <div className="space-y-4">
                <nav className="flex-1 space-y-1">
                  <a href="/" className={getNavLinkClass("/")}>
                    <GlobeAltIcon className="flex-shrink-0 w-5 h-5 mr-4" />
                    Domains
                  </a>
                  <a
                    href="/mailboxes"
                    className={getNavLinkClass("/mailboxes")}
                  >
                    <InboxStackIcon className="flex-shrink-0 w-5 h-5 mr-4" />
                    Mailboxes
                  </a>
                  <a
                    href="/marketplace"
                    className={getNavLinkClass("/marketplace")}
                  >
                    <PlusIcon className="flex-shrink-0 w-5 h-5 mr-4" />
                    Marketplace
                  </a>
                </nav>
              </div>

              <div className="py-4 border-t-gray-200 border-t-2">
                <nav className="flex-1 space-y-1">
                  <a href="/settings" className={getNavLinkClass("/settings")}>
                    <Cog8ToothIcon className="flex-shrink-0 w-5 h-5 mr-4" />
                    Settings
                  </a>

                  <a href="/billing" className={getNavLinkClass("/billing")}>
                    <CreditCardIcon className="flex-shrink-0 w-5 h-5 mr-4" />
                    Orders
                  </a>
                </nav>
              </div>
            </div>
          </div>
        </div>
        {/* Page content goes here */}
        <main className="flex-1 p-4 bg-gray-50">
          <Outlet />{" "}
          {/* This is where the routed page content will be displayed */}
        </main>
      </div>
    </div>
  );
}
