import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Button, Text, Img, Input, Warning } from "../../components";
import { verifyOtp } from "api/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Spinner } from "components/Spinner";

function Verify() {
    const navigate = useNavigate()
    const location = useLocation();
    const state = location.state;
    const email = state?.email;
    useEffect(() => {
        if (email === undefined) navigate(-1)
    }, [email])
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false)
    const [otp, setOtp] = useState(Array(4).fill("")); // Initial state for OTP
    const [cBtn, setCBtn] = useState(false);
    const inputRefs = useRef([]); // Array of refs for each input field
    const handleOtpChange = (index, value) => {
        setError('')
        setOtp((prevOtp) => {
            const updatedOtp = [...prevOtp];
            updatedOtp[index] = value;
            return updatedOtp;
        });
        if (value.length === 1 && index < 3) {
            inputRefs.current[index + 1].focus();
        }
        setCBtn(otp.join("").length === 3)
    };

    const handleOtpVerification = async () => {
        setLoading(true)
        try {
            await verifyOtp(email, otp.join(""))
            toast.success("Verified Successfully")
            navigate("/sign-in")
        } catch (error) {
            setError(error.response?.data?.message || 'Unknown error occured')
        }
        setLoading(false)
    };

    return (
        <>
            {email && <>
                <Helmet>
                    <title>Scaledmail</title>
                    <meta name="description" content="Scaledmail" />
                </Helmet>
                <div className="flex justify-center items-center h-[1080px] w-full md:h-auto px-14 py-[358px] md:p-5">
                    <div className="flex flex-col items-center w-[36%] md:w-full gap-[31px] p-8 sm:p-5 bg-white-A700 shadow-xs rounded-[16px]">
                        {
                            error &&
                            <Warning text={error} showImage={false} />
                        }
                        <Img src="images/img_image_7.png" alt="imageseven_one" className="w-[50%] object-cover" />
                        <div className="flex flex-col items-center w-[68%] md:w-full gap-1">
                            <Text size="lg" as="p" className="tracking-[0.18px]">
                                Enter 4 Digits Code
                            </Text>
                            <Text as="p" className="!text-gray-600 tracking-[0.15px] text-center leading-6">
                                Enter the 4 digits code that you received on your email.
                            </Text>
                        </div>
                        <div className="flex sm:flex-col self-stretch justify-center gap-4">
                            {Array.from({ length: 4 }).map((_, index) => (
                                <Input
                                    key={index} // Add key for each input
                                    shape="round"
                                    maxLength={1} // Limit input to one character
                                    name={`otp-${index}`} // Give each input a unique name
                                    className="self-stretch text-center"
                                    style={{ textAlign: "center" }}
                                    onChange={(e) => handleOtpChange(index, e.target.value)}
                                    ref={(el) => (inputRefs.current[index] = el)} // Assign ref to array
                                />
                            ))}
                        </div>
                        <div className="flex flex-col self-stretch items-center gap-[19px]">
                            {
                                loading ? <Spinner /> :
                                    <Button disabled={!cBtn} shape="round" className="w-full sm:px-5 tracking-[1.25px] uppercase font-medium" onClick={handleOtpVerification}>
                                        Continue
                                    </Button>
                            }
                            <Text as="p" className="flex tracking-[0.50px]">
                                <span className="text-gray-900">Go to sign in page&nbsp;</span>
                                <a href="sign-in" className="text-teal-600 font-bold underline">
                                    Sign in
                                </a>
                            </Text>
                        </div>

                    </div>
                </div>
            </>
            }
        </>
    );
}

export default Verify;