import { getPayments } from "api/payment";
import { SelectBox } from "components";
import { Spinner } from "flowbite-react";
import OrderDetail from "modals/OrderDetail";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatTimestamp } from "utils/utils";

function Billing() {
  const { userObj } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const loadSubscriptions = async () => {
    setLoading(true);
    try {
      const dt = await getPayments(userObj.token, userObj.organizationId);
      setResults(dt);
    } catch (error) {
      console.error("Error making request:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSubscriptions();
  }, []);

  const handleOrderClick = (result) => {
    setShowModal(result);
  };
  return (
    <div className="py-12 bg-white sm:py-16 lg:py-20">
      {loading ? (
        <Spinner />
      ) : (
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex items-center justify-between">
            <p className="text-xl font-bold text-gray-900">Orders</p>
            {/* <div className="flex gap-2 w-1/2">
              <SelectBox
                className="w-full"
                options={[
                  {
                    label: "Active",
                    value: "active",
                  },
                  {
                    label: "In Processing",
                    value: "in-processing",
                  },
                ]}
              />
              <SelectBox
                className="w-full"
                options={[
                  {
                    label: "Domains",
                    value: "domain",
                  },
                  {
                    label: "Domain Swap",
                    value: "domain-swap",
                  },
                ]}
              />
            </div> */}
            {/* <div className="inline-flex items-center justify-end">
              <label
                htmlFor="sort"
                className="text-sm font-medium text-gray-900"
              >
                Sort:{" "}
              </label>
              <select
                id="sort"
                name="sort"
                className="block w-full py-2 pl-1 pr-10 text-base border-gray-300 border-none rounded-lg focus:outline-none focus:ring-teal-300 focus:border-teal-300 sm:text-sm"
              >
                <option>Popularity</option>
              </select>
            </div> */}
          </div>

          {/* Make the table scrollable */}
          <div
            className="flex flex-col mt-4 lg:mt-8"
            style={{ height: "400px", overflowY: "scroll" }}
          >
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <table className="min-w-full lg:divide-y lg:divide-gray-200">
                  <thead className="table-header-group">
                    <tr>
                      <th className="py-3.5 pl-4 pr-3 text-left text-sm whitespace-nowrap font-medium text-gray-500 sm:pl-6 md:pl-0">
                        ID
                      </th>
                      <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                        Product
                      </th>
                      <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                        Date
                      </th>
                      <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                        Price
                      </th>
                      <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                        Type
                      </th>
                      {/* <th className="py-3.5 px-3 text-left text-sm whitespace-nowrap font-medium text-gray-500">
                        Status
                      </th> */}
                      <th className="relative py-3.5 pl-3 pr-4 sm:pr-6 md:pr-0">
                        <span className="sr-only">Actions</span>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="divide-y divide-gray-200">
                    {results &&
                      results.subscriptions.map((result, i) => (
                        <tr
                          key={i}
                          className="hover:bg-gray-100 cursor-pointer"
                          onClick={() => handleOrderClick(result)}
                        >
                          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap sm:pl-6 md:pl-0">
                            #{result.id.replace("rec", "")}
                          </td>

                          <td className="px-4 py-4 text-sm font-bold text-gray-900 whitespace-nowrap">
                            <div className="inline-flex items-center">
                              {/* <img
                            className="flex-shrink-0 object-cover w-8 h-8 mr-3 rounded-full"
                            src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/table-list/1/clarity-landing-logo.png"
                            alt=""
                          /> */}
                              {/* {result["Total Domains"]}Domain -{" "}
                              {result["Total Mailboxes"]} Mailboxes */}
                              {result["Description"]}
                            </div>
                          </td>

                          <td className="px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                            {formatTimestamp(result["Created At"])}
                          </td>

                          <td className="px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap">
                            ${result.Amount}
                          </td>
                          <td className="px-4 py-4 text-sm font-bold text-gray-900 lg:table-cell whitespace-nowrap">
                            {result["Type"]}
                          </td>

                          {/* <td className="px-4 py-4 text-sm font-medium text-gray-900 lg:table-cell whitespace-nowrap">
                            <div className="inline-flex items-center">
                              <svg
                                className="mr-1.5 h-2.5 w-2.5 text-green-500"
                                fill="currentColor"
                                viewBox="0 0 8 8"
                              >
                                <circle cx="4" cy="4" r="3" />
                              </svg>
                              {
                                // result['Email Sequencer']?.length>0?result['Email Sequencer'][0]:""
                                result.Status
                              }
                            </div>
                          </td> */}

                          {/* <td className="px-4 py-4 text-sm font-medium text-right text-gray-900 whitespace-nowrap">
                            <button
                              type="button"
                              className="inline-flex items-center justify-center w-8 h-8 text-gray-400 transition-all duration-200 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-300"
                            >
                              <svg
                                className="w-6 h-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth="2"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M5 12h.01M12 12h.01M19 12h.01M6 12a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0zm7 0a1 1 0 11-2 0 1 1 0 012 0z"
                                ></path>
                              </svg>
                            </button>
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {showModal && (
            <OrderDetail
              userObj={userObj}
              showModal={showModal !== null}
              setShowModal={setShowModal}
              selectedOrder={showModal}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default Billing;
